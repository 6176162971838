import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "@ui/wrapper"
import Counter from "@components/counter/layout-01"
import { useStaticQuery, graphql } from "gatsby"
import Text from "@ui/text"
import Image from "@ui/image"
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types"
import {
  HeroWrapper,
  HeroTextBox,
  ImageBoxWrap,
  ImageBoxOne,
  HeroSeparator,
  StyledTitle,
  StyledSubtitle,
} from "./style"

const HeroArea = () => {
  const heroData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "nosotros-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 480)
        }
      }
    }
  `)
  const imagen = heroData.file.childImageSharp
  return (
    <HeroWrapper>
      <Container fluid>
        <Row alignItems="center">
          <Col md={6}>
            <HeroTextBox>
              <StyledSubtitle>¿Quienes somos?</StyledSubtitle>
              <StyledTitle>Somos Prodental</StyledTitle>
              <Text>
                ProDental es un consultorio especializado en atención
                odontológi- ca. Apasionados por nuestra profesión ponemos a la
                disposición de nuestros pacientes nuestra experiencia y
                formación para resolver sus problemas dentales. <br />
                <br />
                Hoy día, con el avance extraordinario y continuo de los conoci-
                mientos y tecnologías en los tratamientos dentales, sólo es
                posible hacer tratamientos de calidad si los hace un dentista
                que esté espe- cializado en cada tratamiento. En ProDental somos
                especialistas, ofrecemos a nuestros clientes un correcto
                diagnostico y el profe- sional especializado para cada caso
                específico. Asegurando el cuidado de su salud dental y estética.
              </Text>
            </HeroTextBox>
          </Col>
          <Col md={6}>
            <ImageBoxWrap>
              <ImageBoxOne>
                <Image src={imagen} alt="Somos Prodental" />
              </ImageBoxOne>
            </ImageBoxWrap>
          </Col>
        </Row>
        <Row justifyContent="center" mt="30px" pb="90px">
          <Col md={3} sm={6} key="clientes">
            <Counter
              simbol="%"
              mb="30px"
              text="Clientes satisfechos"
              countTo="100"
            />
          </Col>
          <Col md={3} sm={6} key="pacientes">
            <Counter
              simbol="+"
              mb="30px"
              text="Pacientes por mes"
              countTo="40"
            />
          </Col>
          <Col md={3} sm={6} key="anos">
            <Counter mb="30px" text="Años de experiencia" countTo="15" />
          </Col>
        </Row>
      </Container>
      <HeroSeparator>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1557.41 227.87">
          <path d="M1556.91.66V227.1l-1556,.27S.36,124.17.53,124.26C400.41,334.1,1556.91.66,1556.91.66Z" />
        </svg>
      </HeroSeparator>
    </HeroWrapper>
  )
}

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
}

export default HeroArea
