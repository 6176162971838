import * as React from "react"
import PropTypes from "prop-types"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/layout-03"
import Footer from "@layout/footer/layout-01"
import HeroArea from "@containers/nosotros/hero/layout-01"
import EquipoArea from "@containers/nosotros/equipo-area"
import Especialidades from "@containers/index/especialidades-area"
import { headerData, footerData } from "@constants"

const NosotrosPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Nosotros" />
    <Header data={headerData} />

    <main className="site-wrapper-reveal">
      <HeroArea />
      <EquipoArea />
      <Especialidades />
    </main>
    <Footer data={footerData} />
  </Layout>
)

NosotrosPage.propTypes = {
  pageContext: PropTypes.shape({}),
  location: PropTypes.shape({}),
}

export default NosotrosPage
