import React from "react"
import Image from "@ui/image"
import { Container, Row, Col } from "@ui/wrapper"
import {
  EspecialistaWrap,
  EspecialistaInfo,
  EspecialistaNombre,
  EspecialistaFoto,
} from "./style"

const Especialista = ({ especialista }) => {
  return (
    <EspecialistaWrap>
      <Container>
        <Row>
          <Col lg={6}>
            <EspecialistaFoto>
              <Image src={especialista.foto} alt={especialista.nombre.text} />
            </EspecialistaFoto>
          </Col>
          <Col lg={6}>
            <EspecialistaInfo>
              <EspecialistaNombre>
                {especialista.nombre.text}
              </EspecialistaNombre>
              <p>{especialista.titulo.text}</p>
              <p>{especialista.email.text}</p>
              <p>COP: {especialista.cop.text}</p>
            </EspecialistaInfo>
          </Col>
        </Row>
      </Container>
    </EspecialistaWrap>
  )
}

export default Especialista
