import styled from "styled-components"

export const EspecialistaWrap = styled.div`
  margin: 60px 0;
`

export const EspecialistaInfo = styled.div`
  margin: 20px 40px;
  min-width: 300px;
  line-height: 1.5;
  p {
    margin-bottom: 0;
  }
`

export const EspecialistaNombre = styled.h5`
  margin-bottom: 10px;
`

export const EspecialistaFoto = styled.div``
